<template>
	<div id="body">
		<div id="wx-banner">
			<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bxbanner.jpg" >
		</div>
		<div id="wx_tit">
			<div id="wx_tit_header">
				<p>专属报修小程序，手机扫码一键报修</p>
				<span>融恒能报修小程序，能让您实现扫码一步报修，在线跟踪工单状态，在线评价服务质量提升您的售后服务体验</span>
			</div>
			<div id="wx-img">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phonebx.jpg" >
			</div>
			<div id="wx_tit_main">
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='weixin1'></icon-svg>
					<p>扫码报修</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='dizhi'></icon-svg>
					<p>在线跟踪</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='fazhanlicheng'></icon-svg>
					<p>在线评价</p>
				</div>
				<div id="wx_tit_main_box">
					<icon-svg class='iconclas' iconClass='qianyue'></icon-svg>
					<p>在线留言</p>
				</div>
			</div>
			<div id="WX-shop" style="height: 550PX;">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonebx001.png" >
				<div id="WX-shop_NAME">
					<p id="sub">微信报修</p>
					<p id="Ti">微信小程序   扫码报修</p>
					<span>让您售后无忧  设备坏了无需找什么资料，保修卡发票，一键扫描，在线查询商品的保修期，系统自动识别您的信息，自动为您分配最近的服务人员让您全程实时掌控。</span>
				</div>
			</div>
			<div id="WX-shop">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/phonebx002.jpg" >
				<div id="WX-shop_NAME" style="margin-top: 15%;">
					<p id="Ti">短信通知</p>
					<span>从报修开始融恒修平台将实时把维修人员，维修的动态过程以微信消息的方式通知您，让您实时可以查看到维修人员给您设备维修的状态。</span>
				</div>
			</div>
			<div id="WX-shop" style="height: 550PX; margin-top:-125px">
				<img src="https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/bxpj.jpg" >
				<div id="WX-shop_NAME">
					<!-- <p id="sub"></p> -->
					<p id="Ti">在线评价</p>
					<span>提升您的服务体验，让您对工程师的服务可以在线评价，可以实时反馈信息到公司平台，开通实时投诉通道，另有在线知识库可以让您随时查询您的设备的说明书以及您的维修记录</span>
				</div>
			</div>
			<div id="paybgc" style="padding-top: 0;">
				<div style="background:rgba(0,0,0,0.5);width: 100%;height: 100%;padding-top:15% ;">
					<i class="el-icon-circle-check"></i>
					<p>帮你开台微信报修小程序</p>
					<span>扫码报修</br>
								自动对接您的PC端报修系统，让您的微信报修工单，自动和您报修后台程序，和工程师的维修端自动处理，让您实现，报修、接单的自动化</span>
				</div>
			</div>
		</div>
		<div id="wshopbox">
			<p id="on_wx">在线体验报修小程序</p>
			<p id="tw_wx">您手机里的报修小程序，扫码报修一步到位 </p>
			<p id="tw_wx">自动报修 | 在线评价 | 在线跟踪 | 在线查询</p>
			<p id="tw_wx" style="color: #FF0000;font-size: 26px;margin: 0;">￥365</p>
			<button @click="tyfun" type="button">立即体验</button>
		</div>
		<footers></footers>
	</div>
</template>

<script>
	import footers from '../FOOTER.vue'
	export default{
		components: {
			footers
		},
		methods:{
			tyfun(){
				this.$router.push('/PhoneStore')
			}
			
		}
		}
</script>

<style scoped="scoped" lang="less">
	#wx-banner{
		width: 100%;
		height: 200px;
		img{
			width: 100%;
			height: 100%;
		}
	}
	#wx_tit{
		width: 100%;
		
		// background-color: #FF0000;
		text-align: center;
		padding-top: 5%;
		#wx_tit_header{
			width: 100%;
			height: 140px;
			p{
				font-size: 22px;
			}
			span{
				display: block;
				width: 90%;
				margin-left: 5%;
				font-size: 14px;
				color: #6f6f6f;
			}
		}
		#wx_tit_main{
			width: 100%;
			height: 200px;
			display: flex;
			// flex-wrap: wrap;
			justify-content: flex-start;
			align-items: center;
			#wx_tit_main_box{
				width:25%;
				height: 49%;
				margin-left: 1%;
				.iconclas{
					font-size: 40px;
				}
				p{
					font-size: 18px;
					font-weight: bold;
				}
			}
		}
		#WX-shop{
			width: 100%;
			height: 700px;
			img{
				max-width: 100%;
				max-height: 80%;
			}
			#WX-shop_NAME{
				#sub{
					font-size: 20px;
					margin-top: 3%;
				}
				#Ti{
					font-size: 24px;
				}
				span{
					display: block;
					font-size: 14px;
					width: 80%;
					margin-left: 10%;
				}
			}
		}
		#paybgc{
			width: 100%;
			height: 300px;
			background-image: url('https://bxexample.oss-cn-shanghai.aliyuncs.com/OfficialWebsite/Phonepay.jpg');
			background-size: 100% 100%;
			color: #FFFFFF;
			text-align: center;
			padding-top: 20%;
			i{
				font-size: 50px;
				margin-bottom: 1rem;
			}
			p{
				font-size: 24px;
			}
			span{
				display: block;
				width: 80%;
				margin-left: 10%;
				font-size: 14px;
			}
		}
	}
	#wshopbox{
		width: 100%;
		height: 300px;
		background-color: #eaeaea;
		text-align: center;
		padding-top: 15%;
		#on_wx{
			font-size: 28px;
			color: #fd3f31;
		}
		#tw_wx{
			font-size: 17px;
		}
		button{
			width: 40%;
			height: 50px;
			background-color: #fd3f31;
			border-radius: 5px;
			color: #FFFFFF;
		}
	}
	// #wx-img{
	// 	width: 100%;
	// 	img{
	// 		width: 100%;
	// 	}
	// }
</style>
